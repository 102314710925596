import Modal from "@/components/shared/modal";
import {
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
import Image from "next/image";
import ReactMarkdown from "react-markdown";

const MD_NEWLINE = "  ";
const md_thanks = `お申し込み頂きありがとうございました。${MD_NEWLINE}
入力頂いたメールアドレス宛に完了メールをお送りします。`;

const DemoModal = ({
  showDemoModal,
  setShowDemoModal,
}: {
  showDemoModal: boolean;
  setShowDemoModal: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <Modal showModal={showDemoModal} setShowModal={setShowDemoModal}>
      <div className="w-full overflow-hidden md:max-w-md md:rounded-2xl md:border md:border-gray-100 md:shadow-xl">
        <div className="flex flex-col items-center justify-center space-y-3 bg-white px-4 py-6 pt-8 text-center md:px-16">
          <a href="">
            <Image
              src="/onex.webp"
              alt="onex Logo"
              className="h-auto w-auto"
              width={60}
              height={60}
            />
          </a>
          <h3 className="font-display text-2xl font-bold">
            申し込みが完了しました
          </h3>
          <p className="text-sm text-gray-500">
            <ReactMarkdown>{md_thanks}</ReactMarkdown>
          </p>
        </div>
      </div>
    </Modal>
  );
};

export function useDemoModal() {
  const [showDemoModal, setShowDemoModal] = useState(false);

  const DemoModalCallback = useCallback(() => {
    return (
      <DemoModal
        showDemoModal={showDemoModal}
        setShowDemoModal={setShowDemoModal}
      />
    );
  }, [showDemoModal, setShowDemoModal]);

  return useMemo(
    () => ({ setShowDemoModal, DemoModal: DemoModalCallback }),
    [setShowDemoModal, DemoModalCallback],
  );
}
