import Card from "@/components/home/card";
import Layout from "@/components/layout";
import Balancer from "react-wrap-balancer";
import { motion } from "framer-motion";
import {
  FADE_IN_ANIMATION_SETTINGS,
  FADE_DOWN_ANIMATION_VARIANTS,
} from "@/lib/constants";
import { Github, Onex } from "@/components/shared/icons";
import WebVitals from "@/components/home/web-vitals";
import ComponentGrid from "@/components/home/component-grid";
import Image from "next/image";
import Link from "next/link";
import { useSignInModal } from "@/components/layout/sign-in-modal";
import { useSession, signIn, signOut } from "next-auth/react";
import UserDropdown from "@/components/layout/user-dropdown";

export default function Home() {
  const { data: session, status } = useSession();
  console.log(JSON.stringify(session));

  const { SignInModal, setShowSignInModal } = useSignInModal();
  return (
    <Layout>
      <SignInModal />
      <motion.div
        className="max-w-xl px-5 xl:px-0"
        initial="hidden"
        whileInView="show"
        animate="show"
        viewport={{ once: true }}
        variants={{
          hidden: {},
          show: {
            transition: {
              staggerChildren: 0.15,
            },
          },
        }}
      >
        <motion.h1
          className="bg-gradient-to-br from-black to-stone-500 bg-clip-text text-center font-display text-4xl font-bold tracking-[-0.02em] text-transparent drop-shadow-sm md:text-7xl md:leading-[5rem]"
          variants={FADE_DOWN_ANIMATION_VARIANTS}
        >
          <Balancer>楽々Eventing</Balancer>
        </motion.h1>
        <motion.p
          className="mt-6 text-center text-gray-500 md:text-xl"
          variants={FADE_DOWN_ANIMATION_VARIANTS}
        >
          <Balancer>やりたいことに、全ての時間を。</Balancer>
        </motion.p>
        <motion.div
          className="read-only mx-auto mt-6 flex items-center justify-center space-x-5 "
          variants={FADE_DOWN_ANIMATION_VARIANTS}
        >
          {!session && status !== "loading" ? (
            <motion.button
              className="rounded-full border border-black bg-black p-1.5 px-4 text-sm text-white transition-all hover:bg-white hover:text-black"
              onClick={() => setShowSignInModal(true)}
              {...FADE_IN_ANIMATION_SETTINGS}
            >
              Sign In
            </motion.button>
          ) : (
            <UserDropdown />
          )}
        </motion.div>
      </motion.div>
      {/* here we are animating with Tailwind instead of Framer Motion because Framer Motion messes up the z-index for child components */}
      <div className="my-10 grid w-full max-w-screen-xl animate-[slide-down-fade_0.5s_ease-in-out] grid-cols-1 gap-5 px-5 md:grid-cols-4 xl:px-0">
        {features.map(({ title, description, demo, link }) => (
          <Card
            key={title}
            title={title}
            description={description}
            demo={
              title === "Beautiful, reusable components" ? (
                <ComponentGrid />
              ) : (
                demo
              )
            }
            link={link}
          />
        ))}
      </div>
    </Layout>
  );
}

const features = [
  {
    title: "イベント",
    description: "",
    demo: (
      <div className="flex items-center justify-center space-x-10">
        <Image alt="event logo" src="/event.png" width={80} height={80} />
      </div>
    ),
    link: "/events",
  },
  {
    title: "管理ユーザー",
    description: "",
    demo: (
      <div className="flex items-center justify-center space-x-10">
        <Image alt="user logo" src="/user.png" width={80} height={80} />
      </div>
    ),
    link: "/events",
  },
  {
    title: "参加者",
    description: "",
    demo: (
      <div className="flex items-center justify-center space-x-10">
        <Image alt="group logo" src="/group.png" width={80} height={80} />
      </div>
    ),
    link: "/events",
  },
  {
    title: "フォーム",
    description: "",
    demo: (
      <div className="flex items-center justify-center space-x-10">
        <Image alt="form logo" src="/form.png" width={80} height={80} />
      </div>
    ),
    link: "/events",
  },
  {
    title: "受付",
    description: "",
    demo: (
      <div className="flex items-center justify-center space-x-10">
        <Image
          alt="attendance logo"
          src="/attendance.png"
          width={80}
          height={80}
        />
      </div>
    ),
    link: "/events",
  },
];
